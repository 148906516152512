// Make ConfAPI globally available
var ConfStore = ConfStore || false;
var hideEndPrice = Cookies.get('hideEndPrice');

hideEndPrice = hideEndPrice === "true" ? true : false;

ConfStore = new Vuex.Store({ 
  state: {
    car: false,
    wheeldetails: false,
    wheels: [],
    filteredWheels: [],
    tires: [],
    filteredTires: [],
    cart: [],
    showFilters: false,
    hideEndPrice: hideEndPrice,
    activeFilterCount: 0,
    loading: false
  },
  mutations: {
    set_car: function (state, car) {
      state.car = car;
      MainApp.debug && console.log('Car set:', car);
    },
    set_cart: function (state, cart) {
      state.cart = cart;
      MainApp.debug && console.log('Cart items set:', cart);
    },
    set_wheeldetails: function (state, wheeldetails) {
      state.wheeldetails = wheeldetails;
    },
    set_wheels: function (state, wheels) {
      state.wheels = wheels;
    },
    set_filtered_wheels: function (state, filteredWheels) {
      state.filteredWheels = filteredWheels;
      if(filteredWheels && filteredWheels.length > 0) {
        state.showFilters = true;
      }
    },
    set_tires: function (state, tires) {
      state.tires = tires;
    },
    set_filtered_tires: function (state, filteredTires) {
      state.filteredTires = filteredTires;
      if(filteredTires && filteredTires.length > 0) {
        state.showFilters = true;
      }
    },
    set_active_filter_count: function (state, count) {
      state.activeFilterCount = count;
    },
    set_loading: function (state, loading) {
      state.loading = loading;
    },
    toggle_end_price: function (state) {
      state.hideEndPrice = !state.hideEndPrice;
      Cookies.set('hideEndPrice', state.hideEndPrice);
    },
  },
  getters:{
  	getCartData: state => {
      return state.cart
    }
  }
});